import superlogin from './superlogin';
import { API_URL } from '../config';
import { AxiosResponse } from 'axios';
import {
  CouchLikeOperation,
  CreateOperationRequest,
} from 'shared/lib/types/operations';
import { omit } from 'lodash';

class OperationService {
  private teamId: string;

  constructor(teamId: string) {
    this.teamId = teamId;
  }

  async getOperation(key: string): Promise<CouchLikeOperation> {
    const url = `${API_URL}/teams/${
      this.teamId
    }/operations/${encodeURIComponent(key)}`;
    return superlogin
      .getHttp()
      .get(url)
      .then((resp) => resp.data);
  }

  async saveOperation(
    operation: CreateOperationRequest
  ): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/operations`;
    return superlogin.getHttp().post(
      url,
      omit(operation, 'state') // backend routes can't accept state parameter
    );
  }

  /**
   * Starts an operation in the planning state.
   */
  async startOperation(name: string): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/operations`;
    return superlogin.getHttp().patch(url, {
      name,
      action: 'start',
    });
  }

  /**
   * Ends a running operation.
   */
  async endOperation(name: string): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/operations`;
    return superlogin.getHttp().patch(url, {
      name,
      action: 'end',
    });
  }

  async updateOperation({
    name,
    assigneeUserId,
    description,
  }: {
    name: string;
    assigneeUserId?: string | null;
    description?: string;
  }): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/operations`;
    return superlogin
      .getHttp()
      .patch(url, { name, assignee_user_id: assigneeUserId, description });
  }
}

export default OperationService;
