import React, { Fragment, useMemo, useState, useCallback } from 'react';
import { isEqual } from 'lodash';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import validateUtil from '../lib/validateUtil';
import { BlockTypes } from './Blocks/BlockTypes';
import FieldSetProcedureStep from './FieldSetProcedureStep';
import runUtil from '../lib/runUtil';
import MenuSubmitSuggestedEdit from './MenuSubmitSuggestedEdit';
import { Field, Form, Formik } from 'formik';
import { selectProceduresNoDraftsForReleased } from '../contexts/proceduresSlice';
import { useStore } from 'react-redux';
import attachmentUtil from '../lib/attachmentUtil';
import { generateCommentId } from 'shared/lib/idUtil';
import TextareaAutosize from 'react-textarea-autosize';
import runUtilTs from '../lib/runUtilTs';
import { useRunContext } from '../contexts/RunContext';
import PromptBeforeUnload from './Prompt/PromptBeforeUnload';

const CONFIRM_CANCEL_MSG = 'Are you sure you want to cancel? Your changes will be lost';

// TODO: Refactor ProcedureStepEdit to more cleanly separate logic for added steps and full step redlines
const ProcedureStepEdit = ({
  step,
  isPending,
  sectionId,
  stepId,
  precedingStepId,
  onSave,
  onCancel,
  configurePartKitBlock,
  configurePartBuildBlock,
  isDisabled = false,
  stepKey,
  allowComments = false,
}) => {
  const store = useStore();
  const [stepValues, setStepValues] = useState(step);
  const [stepErrors, setStepErrors] = useState<object | null>(null);

  /** @type {{ services: import('../contexts/proceduresSlice').DatabaseServices; currentTeamId: string }} */
  const { services, currentTeamId } = useDatabaseServices();
  const { run } = useRunContext();

  const isDirty = useMemo(() => !isEqual(stepValues, step), [stepValues, step]);

  const onStepFormChanged = useCallback(
    (values) => {
      if (!stepValues) {
        return;
      }
      const cleaned = {
        id: stepValues.id,
        ...values,
      };
      if (isEqual(cleaned, stepValues)) {
        return;
      }
      setStepValues(cleaned);
    },
    [stepValues]
  );

  const onSaveStep = useCallback(
    async ({ editType, commentText }) => {
      const isRedline = editType !== 'Blueline';

      const sectionAndStepIds = validateUtil._getAllSectionAndStepIds(run);
      const procedureWithPendingStep = precedingStepId
        ? runUtil.getProcedureWithPendingStep(run, stepValues, precedingStepId)
        : runUtilTs.replaceStep({
            run,
            sectionId,
            stepId,
            updatedStep: stepValues,
          });
      const procedureMap = validateUtil.getProcedureMap(procedureWithPendingStep);
      const procedures = selectProceduresNoDraftsForReleased(store.getState(), currentTeamId); // get state on demand to prevent re-rendering of component every time procedures change
      const { errors } = await validateUtil.validateStep({
        step: stepValues,
        teamId: currentTeamId,
        sectionAndStepIds,
        procedureMap,
        procedures,
        showRedlineValidation: Boolean(precedingStepId), // Only validate added steps, not full step redlines.
      });
      setStepErrors(errors);
      if (Object.keys(errors).length !== 0) {
        return;
      }
      await attachmentUtil.uploadAllFilesFromStep(stepValues, services.attachments);

      const trimmedCommentText = commentText?.trim() ?? '';
      const stepComments = trimmedCommentText
        ? [
            {
              id: generateCommentId(),
              text: commentText,
            },
          ]
        : [];
      return onSave && onSave(stepValues, isRedline, stepComments);
    },
    [run, precedingStepId, stepValues, sectionId, stepId, store, currentTeamId, services.attachments, onSave]
  );

  const onCancelStep = useCallback(
    (values) => {
      if ((isDirty || values.commentText.trim()) && !window.confirm(CONFIRM_CANCEL_MSG)) {
        return;
      }
      return onCancel && onCancel();
    },
    [isDirty, onCancel]
  );

  return (
    <Fragment>
      <tbody aria-label="Step" role="region">
        <tr>
          <td colSpan={3} className="px-8">
            {/* Step content */}
            <FieldSetProcedureStep
              step={stepValues}
              isPending={isPending}
              errors={stepErrors}
              isCollapsed={false}
              isStepHeadersEnabled={false} // Disabling initially to limit scope
              precedingStepId={precedingStepId}
              enabledContentTypes={[
                BlockTypes.Alert,
                BlockTypes.Text,
                BlockTypes.Attachment,
                BlockTypes.FieldInput,
                BlockTypes.FieldInputTable,
                BlockTypes.TableInput,
                BlockTypes.Expression,
                BlockTypes.JumpTo,
                BlockTypes.ProcedureLink,
                BlockTypes.Commanding,
                BlockTypes.Telemetry,
                BlockTypes.Requirement,
                BlockTypes.ExternalItem,
                BlockTypes.Reference,
                BlockTypes.PartKit,
                BlockTypes.PartBuild,
                BlockTypes.PartUsage,
                BlockTypes.InventoryDetailInput,
                BlockTypes.ToolCheckOut,
                BlockTypes.ToolCheckIn,
                BlockTypes.ToolUsage,
                BlockTypes.TestCases,
              ]}
              onFieldRefChanged={() => null}
              onStepCollapse={() => null}
              onStepFormChanged={onStepFormChanged}
              configurePartKitBlock={configurePartKitBlock}
              configurePartBuildBlock={configurePartBuildBlock}
              customStepKey={stepKey}
              canModifyContentStructure={Boolean(precedingStepId)} // Only allow modifying content structure for a new added step.
            />
            <Formik
              initialValues={{
                editType: step.run_only ? 'Blueline' : 'Redline',
                commentText: '',
              }}
              onSubmit={onSaveStep}
            >
              {({ handleSubmit, isSubmitting, values }) => (
                <Form onSubmit={handleSubmit} className="w-full">
                  {allowComments && (
                    <Field className="py-2" name="commentText">
                      {({ field }) => (
                        <TextareaAutosize
                          placeholder="Comment on suggested edits"
                          className="mt-2 w-full px-3 py-2 resize-none pr-16 rounded border-2 border-gray-500"
                          {...field}
                          disabled={isSubmitting}
                        />
                      )}
                    </Field>
                  )}
                  <PromptBeforeUnload shouldPrompt={isDirty || values.commentText.trim()} />
                  <MenuSubmitSuggestedEdit
                    isRedlineSubmitDisabled={isDisabled || (!isDirty && !values.commentText.trim())}
                    isRedlineCancelDisabled={isDisabled}
                    onRedlineCancel={() => onCancelStep(values)}
                    onSubmit={handleSubmit}
                    runOnly={Boolean(step.run_only)}
                  />
                </Form>
              )}
            </Formik>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr className="h-4"></tr>
      </tbody>
    </Fragment>
  );
};

export default ProcedureStepEdit;
