import { useMemo } from 'react';
import diffUtil from '../lib/diffUtil';
import { newStepRedline, REDLINE_TYPE } from 'shared/lib/redlineUtil';
import {
  RedlinedStep,
  RunStep,
  RunStepFullRedline,
  StepDiffElement,
} from 'shared/lib/types/views/procedures';

import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';

interface UseFullStepRedlineProps {
  currentStep: RunStep;
  redlines: Array<RunStepFullRedline>;
}

type UseFullStepRedlineReturn = {
  isCurrentLatest: boolean;
  currentStepCleaned: Partial<RedlinedStep>;
  latestRedline?: RunStepFullRedline;
  latestStepDiff?: Array<StepDiffElement>;
  reversedRedlines: Array<RunStepFullRedline>;
};

const useFullStepRedline = ({
  currentStep,
  redlines = [],
}: UseFullStepRedlineProps): UseFullStepRedlineReturn => {
  const redlinesWithOriginal = useMemo(() => {
    if (redlines[0] && !redlines[0].is_original) {
      const originalPseudoBlueline = newStepRedline({
        step: currentStep,
        userId: 'system',
        pending: false,
        fieldOrBlockMetadata: {},
        isRedline: false,
        createdAt: new Date().toISOString(),
        type: REDLINE_TYPE.FULL_STEP_REDLINE,
      }) as RunStepFullRedline;

      originalPseudoBlueline.is_original = true;
      return [originalPseudoBlueline, ...redlines];
    }
    return redlines;
  }, [currentStep, redlines]);

  const latestRedline =
    redlinesWithOriginal.length > 0
      ? redlinesWithOriginal[redlinesWithOriginal.length - 1]
      : undefined;
  const reversedRedlines = [...redlinesWithOriginal].reverse();

  const currentStepCleaned = useMemo(
    () => diffUtil.prepareRedlinedStepForDiff({ step: currentStep }),
    [currentStep]
  );

  const latestStepDiff = useMemo(() => {
    if (!latestRedline) {
      return;
    }

    const updated = diffUtil.prepareRedlinedStepForDiff({
      step: latestRedline.step,
      stepId: currentStep.id,
    });
    return diffUtil.getStepDiff({
      previous: currentStepCleaned,
      updated,
      ignoredFields: ['actions', 'actions', 'redline_id'],
    });
  }, [latestRedline, currentStep.id, currentStepCleaned]);

  const isCurrentLatest =
    !latestStepDiff?.[0]?.diff_change_state ||
    latestStepDiff?.[0]?.diff_change_state === ARRAY_CHANGE_SYMBOLS.UNCHANGED;

  return {
    isCurrentLatest,
    currentStepCleaned,
    latestRedline,
    latestStepDiff,
    reversedRedlines,
  };
};

export default useFullStepRedline;
