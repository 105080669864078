import sharedDiffUtil from 'shared/lib/diffUtil';
import { Fragment } from 'react';
import PlaceholderBlock from '../Diff/PlaceholderBlock';
import ReviewBlockText from './ReviewBlockText';
import ReviewBlockTelemetry from './ReviewBlockTelemetry';
import ReviewBlockCommanding from './Blocks/ReviewBlockCommanding';
import ProcedureBlockReview from '../Blocks/ProcedureBlockReview';
import ReviewTableInput from '../TableInput/ReviewTableInput';
import ReviewBlockProcedureLink from './ReviewBlockProcedureLink';
import ReviewBlockAttachment from './Blocks/ReviewBlockAttachment';
import { BlockTypes } from '../Blocks/BlockTypes';
import ReviewJumpTo from './ReviewJumpTo';
import ReviewReferenceBlock from './ReviewReferenceBlock';
import ReviewExpressionBlock from './ReviewExpressionBlock';
import ReviewPartKit from '../../manufacturing/components/Review/ReviewPartKit';
import ReviewPartBuild from '../../manufacturing/components/Review/ReviewPartBuild';
import ReviewInventoryDetailInput from '../../manufacturing/components/Review/ReviewInventoryDetailInput';
import ReviewToolCheckOutIn from '../../manufacturing/components/Tools/ReviewToolCheckOutIn';
import ReviewPartUsage from '../../manufacturing/components/Review/ReviewPartUsage';
import ReviewToolUsage from '../../manufacturing/components/Tools/ReviewToolUsage';
import ReviewTestCasesBlock, { TestCasesBlockDiffElement } from '../../testing/components/Review/ReviewTestCasesBlock';
import ReviewFieldInputTable from './ReviewFieldInputTable';
import {
  AttachmentBlockDiffElement,
  CommandingBlockDiffElement,
  ExpressionBlockDiffElement,
  InventoryDetailInputBlockDiffElement,
  JumpToBlockDiffElement,
  ProcedureLinkBlockDiffElement,
  ReviewToolCheckInBlock,
  ReviewToolCheckOutBlock,
  ReviewToolUsageBlock,
  StepBlockDiffElement,
  TelemetryBlockDiffElement,
  TextBlockDiffElement,
} from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../../contexts/DatabaseContext';

interface ReviewBlocksProps {
  block: StepBlockDiffElement;
  onClickPlaceholder?: () => void;
  subStepKeys?: { [blockId: string]: string };
  isHidden?: boolean;
  onRefChanged;
  scrollToBufferRem;
  docState;
  sourceName;
  isValid: boolean;
  onlyHasToTheSideImages: boolean;
  isMobile: boolean;
  toTheSideImages?: Array<unknown>;
  isStepComplete: boolean;
}

const ReviewBlock = ({
  block,
  onClickPlaceholder = () => null,
  subStepKeys = {},
  isHidden = false,
  onRefChanged,
  scrollToBufferRem,
  docState,
  sourceName,
  isValid = true,
  onlyHasToTheSideImages,
  isMobile,
  toTheSideImages = [],
  isStepComplete = false,
}: ReviewBlocksProps) => {
  const { currentTeamId } = useDatabaseServices();
  const type = (sharedDiffUtil.getDiffValue<string>(block, 'type', 'new') ??
    'placeholder') as StepBlockDiffElement['type'];

  return (
    <Fragment key={block.id}>
      {type.toLowerCase() === 'placeholder' && (
        <tr>
          <td />
          <td colSpan={2}>
            <div className="ml-4">
              <PlaceholderBlock onClick={onClickPlaceholder} />
            </div>
          </td>
        </tr>
      )}
      {type.toLowerCase() === 'text' && (
        <ReviewBlockText
          block={block as TextBlockDiffElement}
          blockLabel={subStepKeys[block.id]}
          isHidden={isHidden}
          onRefChanged={onRefChanged}
          scrollMarginTopValueRem={scrollToBufferRem}
        />
      )}
      {/* Render telemetry content row */}
      {/* TODO: Refactor to use ProcedureBlocReview */}
      {type.toLowerCase() === 'telemetry' && (
        <ReviewBlockTelemetry
          blockLabel={subStepKeys[block.id]}
          telemetry={block as TelemetryBlockDiffElement}
          docState={docState}
          isHidden={isHidden}
          isSpacerHidden={false}
        />
      )}
      {/* Render commanding content row */}
      {type.toLowerCase() === 'commanding' && (
        <tr>
          <td />
          <td colSpan={2}>
            <ReviewBlockCommanding
              blockLabel={subStepKeys[block.id]}
              commanding={block as CommandingBlockDiffElement}
              isHidden={isHidden}
              isSpacerHidden={false}
            />
          </td>
        </tr>
      )}
      {/* Render procedure block */}
      {(type.toLowerCase() === 'input' ||
        type.toLowerCase() === 'alert' ||
        type.toLowerCase() === 'requirement' ||
        type.toLowerCase() === 'external_item') && (
        <tr>
          <td />
          <td colSpan={2}>
            <ProcedureBlockReview
              block={block}
              blockLabel={subStepKeys[block.id]}
              isEnabled={false}
              isHidden={isHidden}
              onContentRefChanged={onRefChanged}
              scrollMarginTopValueRem={scrollToBufferRem}
              recorded={undefined}
              isSpacerHidden={undefined}
              isDark={undefined}
            />
          </td>
        </tr>
      )}
      {/* Render Table input */}
      {type.toLowerCase() === 'table_input' && (
        <ReviewTableInput
          content={block}
          blockLabel={subStepKeys[block.id]}
          isHidden={isHidden}
          isSpacerHidden={false}
        />
      )}
      {/* Render procedure link row */}
      {type.toLowerCase() === 'procedure_link' && (
        <tr>
          <td />
          <td colSpan={2}>
            <div className="ml-4 mr-2">
              <ReviewBlockProcedureLink
                blockLabel={subStepKeys[block.id]}
                link={block as ProcedureLinkBlockDiffElement}
                isHidden={isHidden}
                sourceName={sourceName}
              />
            </div>
          </td>
        </tr>
      )}
      {/* Render attachments row */}
      {type.toLowerCase() === 'attachment' &&
        (onlyHasToTheSideImages || isMobile || !toTheSideImages.includes(block)) && (
          <ReviewBlockAttachment
            attachment={block as AttachmentBlockDiffElement}
            blockLabel={subStepKeys[block.id]}
            isHidden={isHidden}
            isSpacerHidden={false}
          />
        )}
      {/* Render jump to row */}
      {type.toLowerCase() === BlockTypes.JumpTo && (
        <ReviewJumpTo
          content={block as JumpToBlockDiffElement}
          blockLabel={subStepKeys[block.id]}
          isHidden={isHidden}
          isValid={isValid}
        />
      )}
      {/* Render reference row */}
      {type.toLowerCase() === BlockTypes.Reference && (
        <ReviewReferenceBlock
          originalReferencedContentId={sharedDiffUtil.getDiffValue(block, 'reference', 'new')}
          originalReferencedSubtype={sharedDiffUtil.getDiffValue(block, 'sub_reference', 'new')}
          originalReferencedFieldIndex={sharedDiffUtil.getDiffValue(block, 'field_index', 'new')}
          blockLabel={subStepKeys[block.id]}
          isHidden={isHidden}
          isValid={isValid}
          diffChangeState={block.diff_change_state}
        />
      )}
      {/* Render expression row */}
      {type.toLowerCase() === BlockTypes.Expression && (
        <ReviewExpressionBlock
          name={sharedDiffUtil.getDiffValue(block, 'name', 'new')}
          tokens={(block as ExpressionBlockDiffElement).tokens}
          blockLabel={subStepKeys[block.id]}
          isHidden={isHidden}
          isValid={isValid}
          diffChangeState={block.diff_change_state}
        />
      )}
      {/* Render part kit */}
      {type.toLowerCase() === BlockTypes.PartKit && (
        <ReviewPartKit
          content={block}
          onRecordValuesChanged={() => null}
          recorded={null}
          isHidden={isHidden}
          isEnabled={false}
          blockLabel={subStepKeys[block.id]}
          isStepComplete={isStepComplete}
          teamId={currentTeamId}
        />
      )}
      {/* Render part kit */}
      {type.toLowerCase() === BlockTypes.PartBuild && (
        <ReviewPartBuild
          content={block}
          recorded={null}
          isHidden={isHidden}
          isEnabled={false}
          blockLabel={subStepKeys[block.id]}
          teamId={currentTeamId}
        />
      )}
      {/* Render inventory detail input */}
      {type.toLowerCase() === BlockTypes.InventoryDetailInput && (
        <tr>
          <td />
          <td colSpan={2}>
            <ReviewInventoryDetailInput
              content={block as InventoryDetailInputBlockDiffElement}
              onRecordValuesChanged={() => null}
              blockLabel={subStepKeys[block.id]}
              isEnabled={false}
            />
          </td>
        </tr>
      )}
      {/* Render tool check-out */}
      {type.toLowerCase() === BlockTypes.ToolCheckOut && (
        <ReviewToolCheckOutIn
          content={block as ReviewToolCheckOutBlock | ReviewToolCheckInBlock}
          type="out"
          teamId={currentTeamId}
        />
      )}
      {/* Render tool check-in */}
      {type.toLowerCase() === BlockTypes.ToolCheckIn && (
        <ReviewToolCheckOutIn
          content={block as ReviewToolCheckOutBlock | ReviewToolCheckInBlock}
          type="in"
          teamId={currentTeamId}
        />
      )}
      {/* Render part usage */}
      {type.toLowerCase() === BlockTypes.PartUsage && (
        <ReviewPartUsage
          content={block}
          isHidden={isHidden}
          blockLabel={subStepKeys[block.id]}
          teamId={currentTeamId}
        />
      )}
      {/* Render tool usage */}
      {type.toLowerCase() === BlockTypes.ToolUsage && (
        <ReviewToolUsage content={block as ReviewToolUsageBlock} teamId={currentTeamId} />
      )}
      {type.toLowerCase() === BlockTypes.TestCases && (
        <tr>
          <td />
          <td colSpan={2}>
            <div className="mr-4">
              <ReviewTestCasesBlock
                content={block as TestCasesBlockDiffElement}
                isHidden={isHidden}
                blockLabel={subStepKeys[block.id]}
              />
            </div>
          </td>
        </tr>
      )}
      {type.toLowerCase() === BlockTypes.FieldInputTable && (
        <tr>
          <td />
          <td colSpan={2}>
            <div className="ml-4 mr-8">
              <ReviewFieldInputTable content={block} />
            </div>
          </td>
        </tr>
      )}
    </Fragment>
  );
};

export default ReviewBlock;
