import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TextLinkify from './TextLinkify';
import DateTimeDisplay from './DateTimeDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import revisions from '../lib/revisions';
import SuggestedEditIcon from './SuggestedEdits/SuggestedEditIcon';
import EditCommentButton from './EditCommentButton';
import FormStepComment from './FormStepComment';
import useCommentHandler from '../hooks/useCommentHandler';
import { wasEdited } from 'shared/lib/comment';
import Button from './Button';
import TooltipOverlay from './TooltipOverlay';
import { Link } from 'react-router-dom';
import { faExternalLinkAlt as fasExternalLinkAlt, faLink } from '@fortawesome/free-solid-svg-icons';
import { getRedlineSourceStepPath } from '../lib/pathUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';

const Comment = ({
  comment,
  onFieldRefChanged,
  onRefChanged,
  error,
  onResolve,
  onUnresolve,
  commentButton,
  editComment,
  isChild,
  onSetParentCollapsed,
  parentCollapsed,
  mentionsEnabled = false,
  hasBorder = false,
  hideContextLink = false,
}) => {
  const { currentTeamId } = useDatabaseServices();
  const [isCollapsed, setIsCollapsed] = useState(parentCollapsed && !isChild);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const isMounted = useRef(true);

  const onCommentRefChanged = useCallback(
    (element) => {
      if (onFieldRefChanged) {
        return onFieldRefChanged(`comment.[${comment.id}]`, element);
      }
      if (onRefChanged) {
        return onRefChanged(comment.id, element);
      }
    },
    [comment.id, onFieldRefChanged, onRefChanged]
  );

  useEffect(() => {
    setIsCollapsed(parentCollapsed);
  }, [parentCollapsed]);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const isRedlineComment = useMemo(() => revisions.isRedlineComment(comment), [comment]);

  const resolveComment = useCallback(() => {
    setIsRequestInProgress(true);

    return onResolve(comment.id).finally(() => {
      if (!isMounted.current) {
        return;
      }
      setIsRequestInProgress(false);
    });
  }, [comment, onResolve]);

  const unresolveComment = useCallback(() => {
    setIsRequestInProgress(true);

    return onUnresolve(comment.id).finally(() => {
      if (!isMounted.current) {
        return;
      }
      setIsRequestInProgress(false);
    });
  }, [comment, onUnresolve]);

  const toggleCollapse = useCallback(() => {
    if (typeof onSetParentCollapsed === 'function') {
      onSetParentCollapsed();
    } else {
      setIsCollapsed((isCollapsed) => !isCollapsed);
    }
  }, [onSetParentCollapsed]);

  const { editCommentContext, submitComment, handleEditClick, handleCancel } = useCommentHandler({
    onEditComment: editComment,
  });

  const contextUrl = useMemo(() => {
    if (!isRedlineComment || hideContextLink) {
      return '';
    }
    return getRedlineSourceStepPath({
      teamId: currentTeamId,
      redline: {
        run_id: comment.source_run_id,
        source_step_id: comment.reference_id,
      },
    });
  }, [comment.reference_id, comment.source_run_id, currentTeamId, hideContextLink, isRedlineComment]);

  return (
    <>
      <div className={`flex flex-col px-3 bg-slate-200 rounded-lg ${hasBorder ? 'border border-slate-600' : ''}`}>
        <div key={comment.id} ref={onCommentRefChanged} style={{ scrollMarginTop: '4rem' }} />
        <div className="py-2 flex flex-row text-gray-600 items-center">
          <div className="flex-1">
            <div className="text-sm flex justify-between">
              <div className="flex flex-row gap-x-1">
                <span className="font-semibold">{comment.user_id}</span>
                <span>
                  <DateTimeDisplay timestamp={comment.created_at} />
                </span>
                {wasEdited(comment.created_at, comment.updated_at) && <span className="text-gray-400">(edited)</span>}
                {contextUrl && (
                  <div>
                    <TooltipOverlay
                      content={
                        <div>
                          See{' '}
                          <Link className="link group" to={{ pathname: contextUrl }} target="_blank">
                            source run
                            <FontAwesomeIcon
                              className="px-1 text-blue-500 group-hover:text-blue-600"
                              icon={fasExternalLinkAlt}
                            />
                          </Link>
                          for context.
                        </div>
                      }
                      delayClose={true}
                    >
                      <FontAwesomeIcon icon={faLink} />
                    </TooltipOverlay>
                  </div>
                )}
              </div>
              {isRedlineComment && (
                <div>
                  <SuggestedEditIcon isBlueline={false} description="comment" />
                </div>
              )}
            </div>
          </div>
          <div className="ml-1 flex flex-row gap-x-2 items-center text-sm">
            {!isChild && comment.resolved && <span>Resolved</span>}
            {editComment && (
              <EditCommentButton
                comment={comment}
                onEdit={() => {
                  if (isCollapsed) {
                    toggleCollapse();
                  }
                  handleEditClick(comment);
                }}
              />
            )}
            {!isChild && (
              <span className="w-2 cursor-pointer" onClick={toggleCollapse}>
                <FontAwesomeIcon
                  aria-label={isCollapsed ? 'Expand Comment' : 'Collapse Comment'}
                  icon={isCollapsed ? 'angle-right' : 'angle-down'}
                />
              </span>
            )}
          </div>
        </div>
        {!isCollapsed && (
          <>
            <div className="py-1">
              {editCommentContext.id === comment.id ? (
                <FormStepComment
                  autoFocus={true}
                  placeholder="Edit comment"
                  initialValues={{ comment: editCommentContext.text, mentions: editCommentContext.mentions }}
                  onSubmit={submitComment}
                  mentionEnabled={mentionsEnabled}
                  onCancel={handleCancel}
                />
              ) : (
                <TextLinkify mentionsEnabled={mentionsEnabled} mentionList={comment.mention_list}>
                  <span className="whitespace-pre-line">{comment.text}</span>
                </TextLinkify>
              )}
            </div>
            <div>
              <div className="flex flex-row gap-x-1 items-center text-sm">
                {onResolve && !comment.resolved && (
                  <Button
                    type="tertiary"
                    leadingIcon="check"
                    isDisabled={isRequestInProgress}
                    onClick={resolveComment}
                    removePadding={true}
                  >
                    Resolve
                  </Button>
                )}
                {!isChild && comment.resolved && (
                  <>
                    <div className="text-gray-600">
                      <span> Resolved by </span>
                      <span className="font-semibold">{comment.resolved_by}</span>
                      <span>
                        , <DateTimeDisplay timestamp={comment.resolved_at} />
                      </span>
                    </div>
                    {onUnresolve && (
                      <button
                        className="flex flex-row gap-x-1 px-1 items-center font-semibold text-blue-600 cursor-pointer hover:text-blue-800 disabled:text-gray-400 disabled:cursor-default"
                        title="Unresolve Comment"
                        disabled={isRequestInProgress}
                        onClick={unresolveComment}
                      >
                        <span>⋅</span>
                        <span>Unresolve</span>
                      </button>
                    )}
                  </>
                )}
                {commentButton}
              </div>
            </div>
          </>
        )}
      </div>

      {error && (
        <div className="flex text-sm text-red-700 ">
          <div>{error}</div>
        </div>
      )}
    </>
  );
};

export default React.memo(Comment);
